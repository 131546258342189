<template>
  <div class="common-table-list systemManage-user-group">
    <div class="location">
      <router-link style="color: #6e6b7b" to="/">{{
        $t("Welcome")
      }}</router-link>
      <span> > {{ $t('System Management')}} > </span>
      <span style="color:#3461FF">{{ $t('User Group') }}</span>
    </div>
    <div class="hd card">
      <div class="handle-button-section">
        <el-button type="primary" size="small" @click="handleAdd">
          <i class="el-icon-plus" />
          {{$t('button.New')}}
        </el-button>
        <el-button type="danger" size="small" @click="handleBatchDel" :disabled="!selection()">
          <i class="el-icon-delete" />
          {{$t('button.Batch Delete')}}
        </el-button>
      </div>
    </div>

    <div class="bd card">
      <!--切换门店 -->
      <div class="table-header">
        <div>
          <span class="text">{{$t('userGroup.Records')}} :{{pageParams.total}}</span>
          <template v-if="userData.userType ===203 || userData.userType ===204">
            <el-select v-model="searchParams.storeQueryType" @change="getData" placeholder="" size="small">
              <el-option :label="$t('ThisStore')" value="2" />
              <el-option :label="$t('ZimenStore')" value="1" />
              <el-option :label="$t('AllStore')" value="0" />
            </el-select>
          </template>
        </div>
      </div>

      <!-- 表格 -->
      <el-table 
        :data="tableData" 
        style="width: 100%"
        table-layout="fixed"
        v-loading="loading"
        ref="normalTable"
        @sort-change="handleSort"
        >
        <el-table-column align="center" type="selection" fixed>
          <el-table-column align="center" width="55" type="selection">
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('userGroup.Store Name')" prop="store_name" min-width="160" header-align="center">
          <el-table-column  prop="storeName" min-width="160" align="center">
            <template slot="header" slot-scope="scope">
              <el-input v-model="searchParams.storeName" @input="getData" size="mini" placeholder="" clearable />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('userGroup.User Group')" prop="role_name" min-width="160" sortable="custom" header-align="center">
          <el-table-column  prop="roleName" min-width="160" align="center">
            <template slot="header" slot-scope="scope">
              <el-input v-model="searchParams.roleName" @input="getData" size="mini" placeholder="" clearable />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('userGroup.Update Time')" min-width="230" prop="update_time" sortable="custom" header-align="center">
          <el-table-column prop="updateTime" min-width="230" align="center">
            <template slot="header" slot-scope="scope">
              <el-date-picker
                v-model="searchParams.updateDate"
                type="daterange"
                start-placeholder=""
                end-placeholder=""
                size="mini"
                @change="handleUpdateTimeChange"
                placement="bottom-start"
                value-format="yyyy-MM-dd"
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('userGroup.Creation Time')"  min-width="230" prop="create_time" sortable="custom" header-align="center">
          <el-table-column prop="createTime" min-width="230" align="center">
            <template slot="header" slot-scope="scope">
              <el-date-picker
                v-model="searchParams.createDate"
                type="daterange"
                start-placeholder=""
                end-placeholder=""
                size="mini"
                @change="handleCreateTimeChange"
                placement="bottom-start"
                value-format="yyyy-MM-dd"
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column fixed="right" :label="$t('userGroup.Action')" width="120" header-align="center">
          <el-table-column  align="center" width="120" >
            <template slot="header" ></template>
            <template slot="default" slot-scope="{row}">
              <div class="operate">
                <span class="table-icon"  @click="handleDelete(row)">
                  <i class="el-icon-delete"></i>
                </span>
                <span class="table-icon"  @click="handleEdit(row)">
                  <i class="el-icon-edit-outline"></i>
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table-column>

      </el-table>

      <!-- 页码 -->
      <pagination :tableInfo="pageParams" @changePage="getData" />

    </div>

    <!-- 创建用户组 -->
    <createUserGroup 
      ref="createUserGroup" 
      :editData="editData" 
      @getData="getData">
    </createUserGroup>

    <!-- 删除弹窗确认 -->
    <deleteConfirmDialog ref="deleteConfirmDialog" @onDeleteConfirm="userGroupDelete"></deleteConfirmDialog>

  </div>
</template>
<script>
  import { userRoleGetList, userRoleDelete, userRoleGet } from "@/libs/api/system-management-user-goup";
  import createUserGroup from './components/createUserGroup'
  import { commonStore } from '@/store/global-select-store'
  import pagination from '@/components/pagination'
  import deleteConfirmDialog from '@/components/delete-confirm-dialog/index'

  export default {
    name:'userGroup',
    components: {
      createUserGroup,
      deleteConfirmDialog,
      pagination
    },
    props: {
      location: {
        type: String,
        default: 'default',
      },
    },
    data() {
      return {
        commonStore,
        userData:JSON.parse(localStorage.getItem('userData')),
        tableData:[],
        editData:{},
        loading:false,
        searchParams:{
          storeId:'', // 门店id
          roleName:'', // 角色名称
          isEffect:0, // 是否有效，0：有效，1：无效
          storeName:'',// 门店名称
          storeQueryType: '0', //门店查询类型 ,0：所有门店，1：子门店，2：当前门店
          startCreateTime:'',// 创建时间 起始时间
          endCreateTime:'',// 创建时间 结束时间
          startUpdateTime:'',// 更新时间 起始时间
          endUpdateTime:'',// 更新时间 结束时间
          orders:[], //用于排序的字段，允许多组，以JSONArray格式保存,['desc:tagIdentity']
          updateDate:['',''], //更新时间
          createDate:['',''] // 创建时间
        },
        pageParams:{
          current:1,//当前页
          size:10,//单页显示最大数
          total:0,
          pageSizeArr:['10','20','50','100','200','500','1000'],
        },
      }
    },
    created(){
      // let userData=JSON.parse(localStorage.getItem('userData'))
      // this.searchParams.storeId = userData.storeId
      commonStore.setInjectCallBack(this.getData)
      this.getData()
    },
    mounted(){

    },
    methods: {
      // 获取数据
      async getData(){
        let { roleName,storeQueryType,isEffect,storeName,createDate,updateDate,orders} = this.searchParams
        let {current,size} = this.pageParams
        let storeId = localStorage.getItem('storeId')
        console.log({createDate})
        let params = {
          storeId,
          roleName,
          storeQueryType,
          isEffect, 
          storeName,
          startCreateTime:createDate ? createDate[0] :'',
          endCreateTime:createDate ? createDate[1] :'',
          startUpdateTime:updateDate ? updateDate[0] :'',
          endUpdateTime:updateDate? updateDate[1] :'',
          orders,
          current,
          size,
        }
        try {
          const res = await userRoleGetList(params)
          let response = res.data
          if(response.state){
            let dataList = response.data.records
            this.tableData = dataList
            this.pageParams.total = response.data.total
          } else{
            this.$message.error(response.message);
          }
        } catch (error) {
          this.$message.error(error.message);
        }
        
      },
      // 获取角色详情
      async userRoleGet(){
        try {
          let params ={
            userRoleId:this.editData.userRoleId
          }
          const res = await userRoleGet(params)
          let response = res.data
          if(response.state){
            this.editData = response.data
            this.$refs.createUserGroup.open()
          } else{
            this.$message.error(response.message);
          }
        } catch (error) {
          this.$message.error(error.message);
        }
        
      },
      // 表格排序
      handleSort(column, prop, order){
        console.log('表格排序',column, prop, order)
        let arr = []
        if(column.order){
          let str1= column.order === "ascending" ? "asc" : "desc";
          str1 = str1 + ':'+ column.prop;
          arr.push(str1)
        } else {
          arr = []
        }
        
        this.searchParams.orders = arr;
        this.getData()
      },
      // 时间选择范围变更---创建时间
      handleCreateTimeChange(val){
        let searchParams ={
          ...this.searchParams
        }
        if(!val){
          searchParams.createDate = ['','']
        }
        this.searchParams = searchParams
        this.getData()
      },
      // 时间选择范围变更---更新时间
      handleUpdateTimeChange(val){
        let searchParams ={
          ...this.searchParams
        }
        if(!val){
          searchParams.updateDate = ['','']
        }
        this.searchParams = searchParams
        this.getData()
      },
      // 新增
      handleAdd(){
        this.editData = {}
        this.$refs.createUserGroup.open()
      },
      // 编辑
      handleEdit(row){
        console.log('编辑',row)
        this.editData = row
        this.userRoleGet()
        // this.$refs.createUserGroup.open()
      },
      // 多选复选框---获取选中的数量
      selection() {
        return this.$refs.normalTable && this.$refs.normalTable.selection.length
      },
      // 删除  ---批量删除
      handleBatchDel(){
        const selection = this.$refs.normalTable.selection
        let deleteData ={}
        let storeIds = selection.map(it => it.userRoleId)
        deleteData.userRoleId = storeIds.join(',')
        this.deleteData = deleteData
        this.$refs.deleteConfirmDialog.open()
        // this.dialogVisible =true
      },
      // 删除  ---单个删除
      handleDelete(row){
        console.log('删除',row)
        this.deleteData = row
        this.$refs.deleteConfirmDialog.open()
        // this.dialogVisible =true
      },
      // api---删除组
      async userGroupDelete(){
        let {userRoleId} = this.deleteData
        let params = {
          userRoleIds:userRoleId
        }
        try {
          const res = await userRoleDelete(params)
          let response = res.data
          if(response.state){
            this.$message({
              message: this.$t('commonMessage.DeleteSucess'),
              type:'success',
            });
            this.$refs.deleteConfirmDialog.close()
            this.getData()
          } else{
            this.$refs.deleteConfirmDialog.close()
            this.$message.error(response.message);
          }
        } catch (error) {
          this.$refs.deleteConfirmDialog.close()
          this.$message.error(error.message);
        }
        
      },
    }
  }
</script>
<style lang="scss" scoped>
  .systemManage-user-group ::v-deep {
    .el-table
      .el-table__fixed-header-wrapper
      .el-table__header
      .is-group
      tr:nth-of-type(2)
      th:nth-of-type(1)
      .cell {
      display: none;
    }
  }
</style>