import router from "../axios";

import config from "../../config/index"
const { baseUrl } = config;

// 获取用户组列表
function userRoleGetList(data) {
  return router({
    url: baseUrl+"userRole/v1/getList",
    data:data,
    method: "POST",
  });
}

// 获取角色详情
function userRoleGet(data) {
  return router({
    url: baseUrl+"userRole/v1/get",
    data:data,
    method: "POST",
  });
}

// 创建角色 
function userRoleCreate(data) {
  return router({
    url: baseUrl+"userRole/v1/create",
    data:data,
    method: "POST",
  });
}

// 更新角色
function userRoleUpdate(data) {
  return router({
    url: baseUrl+"userRole/v1/update",
    data:data,
    method: "POST",
  });
}

// 删除角色
function userRoleDelete(data) {
  return router({
    // url: baseUrl+"userRole/v1/physicalDelete",
    url: baseUrl+"userRole/v1/delete",
    data:data,
    method: "POST",
  });
}

//  获取当前用户下所有角色
function getAllDescendantRole(data) {
  return router({
    url: baseUrl+"userRole/v1/getAllDescendantRole",
    data:data,
    method: "POST",
  });
}

// 根据门店获取其下所有角色 
function getRoleByStoreId(data) {
  return router({
    url: baseUrl+"userRole/v1/getRoleByStoreId",
    data:data,
    method: "POST",
  });
}

export {
  userRoleGetList,
  userRoleGet,
  userRoleCreate,
  userRoleUpdate,
  userRoleDelete,
  getAllDescendantRole,
  getRoleByStoreId
}



