<template>
  <div class="create-store">
    <el-dialog 
      v-model="dialogVisible" 
      :title="formData.userRoleId ? $t('userGroup.User Group Details') : $t('userGroup.Create User Group')"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="700px"
    >
      <div class="form-box">
        <div style="height:70px"></div>
        <el-form 
          label-width="160px"
          style="width: 100%"
          :model="formData"
          ref="form" 
          :rules="rules"
          size="small"
        >
          <el-form-item :label="$t('userGroup.User Group')" prop="roleName">
            <el-input 
              v-model="formData.roleName" 
              placeholder=""
              clearable 
            />
          </el-form-item>

          <!-- 保存按钮 -->
          <div class="form-btn-section">
            <el-button color="#28C76F" size="small" @click="dialogVisible =false" >{{$t('button.Cancel')}}</el-button>
            <el-button color="#28C76F" size="small"  type="success" @click="onSubmit" >{{$t('button.Save')}}</el-button>
          </div>

        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { trim } from 'lodash';
  import { userRoleCreate, userRoleUpdate } from "@/libs/api/system-management-user-goup";
  let loading;

  export default {
    name:'createCustomer',
    components: {
    },
    props: {
      statusList: {
        type: Array
      },
      editData:{
        type:Object
      }
    },
    data() {
      return {
        userData:JSON.parse(localStorage.getItem('userData')),
        dialogVisible:false,
        initFormData:{
          userRoleId:'',
          storeId:'', //门店门店id
          merchantId:'', //	商户id
          roleName:'', //用户角色名称
          isEffect: 0, //	是否有效
          sort: '', // 层级，预留字段，用于各角色间的优先级判断
          roleData:{name:'All'}, //角色权限信息
        },
        formData:{
          userRoleId:'',
          storeId:'', //门店id
          merchantId:'', //	商户id
          roleName:'', //用户角色名称
          isEffect: 0, //	是否有效
          sort: '', // 层级，预留字段，用于各角色间的优先级判断
          roleData:{name:'All'}, //角色权限信息
        },
        rules:{
          roleName: [
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' },
            { max: 100, message: this.$t('formValidate.maxLength'), trigger: 'blur'}
          ], 
        },
        
      }
    },
    created(){

    },
    mounted(){
      
    },
    watch: {
      editData: function(val) {
        console.log('--watch--editData',val)
        if(val && val.userRoleId){
          let data ={
            ...val,
            roleData:{name:'All'}
          }
          this.formData = data
        } else {
          let obj = {...this.initFormData}
          this.formData = obj
        }
        
      }
    },
    methods: {
      // 打开弹窗
      open(){
        this.dialogVisible = true
      },
      // 关闭弹窗
      handleClose(){
        this.$refs.form.resetFields()
        this.dialogVisible = false
      },
      // 表单提交
      onSubmit(){
        loading = this.$loading({
          text:"loading...",
          lock: true,
          background: 'rgba(0, 0, 0, 0.7)',
        })
        this.$refs.form.validate( valid => {
          if (valid) {
            console.log('表单校验通过',)
            this.userRoleCreate()
          } else {
            loading.close()
            console.log('error submit!!');
            return false
          }
        })
      },
      // 请求--- 创建用户组/更新用户组
      async userRoleCreate(){
        let { userRoleId, roleName, isEffect, sort, roleData,rolePerGroupId } = this.formData
        let userData = this.userData
        let params = {
          roleName:trim(roleName),
          isEffect,
          sort,
          roleData,
        }
        let res = null
        try {
          if(userRoleId){
            params.userRoleId = userRoleId
            params.rolePerGroupId = rolePerGroupId
            res = await userRoleUpdate(params)
          } else {
            params.storeId = localStorage.getItem('storeId'),
            params.merchantId = userData.merchantId
            res = await userRoleCreate(params)
          }
          let response = res.data
          if(response.state){
            let message = this.$t('commonMessage.CreateSucess')
            if(userRoleId){
              message = this.$t('commonMessage.ModifySucess')
            }
            this.$message({
              message: message,
              type:'success',
            });
            this.dialogVisible = false
            this.$emit('getData')
            loading.close()
          } else{
            loading.close()
            this.$message.error(response.message);
          }
        } catch (error) {
          loading.close()
          this.$message.error(error.message);
        }
        
      },
    }
  }
</script>
<style lang="scss" scoped>
  .create-store{
    .form-box{
      width: 480px;
      margin: 0 auto;
      min-height: 300px;
    }
    .section-tit{
      position: relative;
      font-weight: 600;
      padding: 10px 10px 10px 20px;
      background: whitesmoke;
      margin-bottom: 20px;
      border-radius: 4px;
      .flag{
        position: absolute;
        width: 4px;
        height: 18px;
        border-radius: 2px;
        background: #35B871;
        top: 0px;
        bottom: 0px;
        left: 10px;
        margin: auto;
      }
    }

    .form-btn-section{
      width: 100%;
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      ::v-deep .el-button{
        width: 110px;
      }
    }
    // 重置样式
    ::v-deep .el-dialog{
      margin-top: 5vh !important;
    }
    ::v-deep .el-dialog__body{
      max-height: calc(82vh);
      overflow-y: scroll;
    }
    .el-select{
      width: 100%;
    }
    .el-button--success{
      width: 100%;
    }
  }
</style>